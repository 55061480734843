import NavBar from "../NavigationMenu/NavBar";
const Header = () => {
 return(
    <header>
            <div className="connector">
                <div className="container liquid-container">
                    <NavBar/>
                </div>
            </div>
        </header>
 );
};

export default Header;