const CompanyAddress = () => {
    return(
        <>
       <div className="container liquid-container pad-15-xs">
                <div className="spacer20"></div>
                
                <div className="footer-information-box contactUsMargin">
                    <div className="footer-info-location">
                        <h1 className="bellSlimBold txtSize32 txtBlack2">India</h1>
                        <div className="footer-location-box">
                            <h2 className="bellSlimBold txtSize18 txtBlack2">Bangalore</h2>
                            <div>
                                <h2 className="bellSlimBold txtSize14 txtBlack2">
                                Registered & Corporate Office
                                </h2>
                                <h2 className="bellSlimBold txtSize14">
                                    BCE GLOBAL TECHNOLOGY CENTRE PRIVATE LIMITED
                                </h2>                                
                                <p>A-402, 4th Floor, Block A, Cyber Park,<br />Electronic City Phase 1,<br />Doddathogur Village, Begur Hobli,<br />Bangalore South,<br />Karnataka, India, 560100</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div> 
        </>    
    )
};

export default CompanyAddress;