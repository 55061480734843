import React , {useEffect} from "react";
import Banner from "../../components/Banner/Banner";
import Tile from "../../components/Tile/Tile";
import SubFooter from "../../components/SubFooter/SubFooter";
import CareerData from "../../json/minify/careerpage.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

const careerBanner = CareerData.bannerSection;
const careerJobCategoryTileList = CareerData.jobTileSection;
const careerTileList = CareerData.offerTileSection;


const Career = () => {
  const handleBannerSearchText = (searchValue:any) =>{
    console.log("searchValue",searchValue);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },[])
    return(
      <>
        <MetaTag title={ CareerData.title } description={ CareerData.description }/>
        <Banner
          isCarousle={false}
          bannerCardPosition={"center"}
          bannerCardColor={""}
          bannerImages={careerBanner}
          stretchFullWidth={true}
          bannerClass={""} 
          bannerJobContentFlag={false}
          handleBannerSearchText={handleBannerSearchText}
          />
        <div className="bgWhite pad-15-xs">


        <div className="container liquid-container">
              <div className="spacer30 hidden-xs"></div>
              <h2 className="bellSlimBold txtBlack txtCenter txtBold txtSize32 letterSpacing
					          txtSize26-xs margin-15-top">Be a part of building a better, connected future.</h2>
              <div className="spacer30"></div>      
              <div className="inline-blck w-100">
                  <div className="bgGray19 left-blck w-30 w-sm-40 pad-30 borderradius w-100-xs contentBox-description-sm"> 
                      <p className="txtBlack txtBold txtSize18">At BCE Global Tech, immerse yourself in exciting projects that are shaping the future of both consumer and enterprise telecommunications.</p>
                      <p className="txtSize16">This involves building innovative mobile apps to enhance user experiences and enable seamless connectivity on-the-go.</p>
                  </div>  
                  <div className="right-blck w-70 w-sm-60 pad-left-20-md pad-left-20-sm w-100-xs pad-top-20-xs">
                  <p className="txtSize16">Our community of technology developers is shaping the future of the telecommunications industry. We offer next-generation products and services for consumers and small to medium businesses, including ultra-fast internet, immersive virtual reality experiences, innovative IoT solutions, and AI-enabled technologies for personalized user experiences and optimized network performance.</p>
                  <p className="txtSize16">We foster a strong culture that nurtures career growth and encourages engagement in the entire technology lifecycle. Our agile teams collaborate on projects from ideation to deployment, fostering continuous learning in the pursuit of meticulously crafted projects. We lead, coach, and support through mentoring, and working in close-knit squads, guided by exceptional leaders.</p>
                  <p className="txtSize16">Thrive in diverse roles like Full Stack Developer, Backend Developer, UI/UX Designer, DevOps Engineer, Cloud Engineer, Data Science Engineer, and Scrum Master at a workplace that encourages you to freely share your bold and different ideas.</p>

                   </div>
              </div>      
          </div>

        
          <Tile
            blockHeaderTitle="At BCE Global Tech we offer fulfilling and rewarding roles that include:"
            blockHeaderDescription=""
            tiles={careerTileList}
            customImageClass=""
            customCardClass={"col-md-3 col-sm-3 width-100-percent-xs"}
            custombodyClass={"bgGray19 pad-2-sm noBorder"}
            customTitleClass={"txtSize16 no-margin-sm mar-neg-top-10"}
            customFooterClass=""
            isBodyLinkTrue={false}
            isFooterLinkTrue={false}
            blockFooterButtons=""
            blockFooterText=""
            baseURL=""
            custombodyHeight={"h-170 h-220-sm"} />
    
          {/* Video Player Container*/}
          {CareerData.videoBannerSection.showVideo && (
            <>                       
              <div className="clear spacer30"></div>
              <div className="container liquid-container">
                <h2 className="bellSlimBold txtCenter txtBlack txtBold txtSize32 letterSpacing
                              txtSize26-xs margin-15-top">{CareerData.videoBannerSection.title}</h2>
                <p className='txtSize16'>{CareerData.videoBannerSection.description}</p>
                <div className="pad-30">
                  <VideoPlayer
                    videoLink={CareerData.videoBannerSection.videolink}
                    controls={CareerData.videoBannerSection.controls}
                  />
                </div>
              </div>              
            </>
          )} 
       

          <Tile
            blockHeaderTitle="Job categories"
            blockHeaderDescription=""
            tiles={careerJobCategoryTileList}
            customImageClass={"w-100"}
            customCardClass={"col-md-4 col-sm-4 width-100-percent-xs"}
            custombodyClass={"career-jobcategorytile"}
            customTitleClass={"bellSlimBold txtSize16 pad-top-12 underline-on-hover"}
            customFooterClass=""
            isBodyLinkTrue={false}
            isFooterLinkTrue={false}
            blockFooterButtons=""
            blockFooterText=""
            baseURL=""
            custombodyHeight={"min-h-57"} />
     


        </div>          

        <SubFooter
          title=""
          buttonText="Contact us"
          buttonUrl="/contactus"
          isCustomInfo={true}
          moreInformationText="For more information on the exciting opportunities with BCE Global Tech"
         
          />
          
      </>
     );
    
};

export default Career;