import React from "react";
import PageAtBottom from "../Layout/PageAtBottom/PageAtBottom";
const Footer = () => {
    const websiteSharingurl=process.env.REACT_APP_WEBSITE_SHARING_URL_LINKEDIN
    return(
        <>
        <footer className="bgWhite">
            <div className="container liquid-container hidden-xs visible-sm visible-lg visible-md">     
            <div className="spacer30"></div> 
            <div className="spacer40"></div>       
                <hr className="footer-line"/>
                <div className="spacer20"></div>
                <div>
                    <div className="col-lg-6 col-md-6 ">
                        <div className="footer-links">
                            <a href="/leadership" className="txtSize14 no-break-white-space">Leadership</a>
                            <span className="txtLightGray txtSize10 pad-5">|</span>
                            <a href="/content/document/BCE GTC Website Privacy Notice v 1.1.pdf" target="_blank" className="txtSize14 no-break-white-space">Privacy</a>
                            <span className="txtLightGray txtSize10 pad-5">|</span>
                            <a href="/content/document/BCE Global Tech Security Policy.pdf" target="_blank" className="txtSize14 no-break-white-space">Security</a>
                            <span className="txtLightGray txtSize10 pad-5">|</span>
                            <a href="/content/document/Website Terms of Use_Legal.pdf" target="_blank" className="txtSize14 no-break-white-space">Legal & regulatory</a>
                            {/* <span className="txtLightGray txtSize10 pad-5">|</span>
                            <a href="#" className="txtSize14 no-break-white-space">Accessiblity</a> */}
                        </div>
                        <div className="spacer10 clear"></div>
                        <div className="txtSize14">© BCE Global Technology Centre Private Limited, 2024. All rights reserved.</div>
                    </div>
                    <div className="col-lg-6 col-md-6 footer-media-box">
                        <ul className="footer-media-items">
                            <li className="footer-media-item"><a href={websiteSharingurl}  target="_blank"><img src="/content/img/linkedin-footer.png" alt="linkedin"/></a></li>
                        </ul>
                    </div>
                </div>
                <div className="spacer30 clear"></div>
            </div>
            <div className="visible-xs pad-15 hidden-sm hidden-lg hidden-md">
                <hr className="footer-line"/>
                <div className="spacer10"></div>
                <a href="/leadership" className="txtSize12 block txtCenter margin-10-bottom no-break-white-space">Leadership</a>
                <a href="/content/document/BCE GTC Website Privacy Notice v 1.1.pdf" target="_blank" className="txtSize12 block txtCenter margin-10-bottom">Privacy</a>
                <a href="/content/document/BCE Global Tech Security Policy.pdf" target="_blank" className="txtSize12 block txtCenter margin-10-bottom">Security</a>
                <a href="/content/document/Website Terms of Use_Legal.pdf" target="_blank" className="txtSize12 block txtCenter margin-10-bottom">Legal & regulatory</a>
                {/* <a href="#" className="txtSize12 block txtCenter margin-10-bottom">Accessiblity</a> */}
                <div className="spacer10 clear"></div>
                <div className="col-lg-6 txtSize12 txtCenter">© BCE Global Technology Centre Private Limited, 2024. All rights reserved.</div>
                <div className="spacer30 clear"></div>
                <div className="col-lg-6 col-md-6 footer-media-box">
                        <ul className="footer-media-items">
                            <li className="footer-media-item"><a href={websiteSharingurl}  target="_blank"><img src="/content/img/linkedin-footer.png" alt="linkedin"/></a></li>
                        </ul>
                    </div>
            </div>
        </footer> 
        <PageAtBottom/>   
        </>    
    )
};

export default Footer;