import React, {ReactNode} from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Route,BrowserRouter } from "react-router-dom";
import Router from "../../router";
export type LayoutProps ={
    bodyContent?:ReactNode;
};

const Layout = () => {
    return(
        <BrowserRouter>
        <Header />
        <main id="maincontent">
            <Router/>
        </main>           
        <Footer />
        </BrowserRouter>
   )
};

export default Layout;