import React, { useEffect } from "react";
import { useParams ,NavLink } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ResourceDetailsData from "../../json/minify/resourcesDetail.json";
import ResourcePageData from "../../json/minify/resourcePage.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import PageTitle from "../../components/Layout/PageTitle/PageTitle";

const resourceBannerList = ResourcePageData.bannerSection;
const reasourseDataJson = ResourceDetailsData;

const ResourceDetails =() => {
    const { resource_name } = useParams();
    const resourceDetails = reasourseDataJson.resources.filter((details) => details.resource_name.includes(resource_name || ''));
    const handleBannerSearchText = (searchValue: any) => {
        console.log("searchValue", searchValue);
      };
      useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      }, [])

    return(
        <>
         <MetaTag title={ ResourcePageData.title } description={ ResourcePageData.description }/>
         <Banner
      isCarousle={false}
      bannerCardPosition={"center"}
      bannerCardColor={""}
      bannerImages={resourceBannerList}
      stretchFullWidth={true}
      bannerClass={""}
      bannerJobContentFlag={false}
      handleBannerSearchText={handleBannerSearchText}
    />
        <div className="clear"></div>  
        <PageTitle title="Resources" />
        <div className="container liquid-container pad-h-15-xs">
        <div className="spacer20" aria-hidden="true"></div>
        <div className="back_button cursor_pointer">
            <NavLink role="button" tabIndex={-1} to="/resources">
              <span >
                <img className="back_button_image pb-1" src="/content/img/big-back-arrow.svg" />
                <span className="pad-left-10">Back</span>
              </span>
            </NavLink>
                
            </div>
        <div className="spacer30 hidden-xs"></div>
        <div>
        <h2 className="bellSlimBold right-blck txtBlack txtCenter txtBold txtSize32 letterSpacing
					          txtSize26-xs margin-15-top">{resourceDetails[0].title}</h2>
        </div>
       
       
        <div>
        <div className="spacer20 clear"></div>
        <div dangerouslySetInnerHTML={{ __html: resourceDetails[0].long_description }}></div>
        </div>
        </div>
       
             
        
        </>

    );

};
export default ResourceDetails