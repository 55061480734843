import Card from "../Card/Card";


interface Links {    
    
    label: string;
    url: string;
    iconUrl: string;
    isExternal:boolean;
}
interface Tile {
    title: string;
    subTitle?:string;
    description: string;
    // links: [];
    // attributes: {
    //   body: string;
    // };
    logoImageUrl: string;
    stackedImageUrl:string;
    showBorder: boolean;
    showSlide:boolean;
    stackedImageFullWidth?: boolean;
    url:string;
    customCardLinks?:Links[];
    
  }
  
  interface TileProps {
    blockHeaderTitle: string;
    blockHeaderDescription?: string;
    tiles: Tile[];
    customImageClass?: string;
    customCardClass?: string;
    custombodyClass?: string;
    customTitleClass?: string;
    customFooterClass?: string;
    isBodyLinkTrue?: boolean;
    isFooterLinkTrue?: boolean;
    blockFooterButtons?: any;
    blockFooterText?: string;
    baseURL: string;
    custombodyHeight:string;
    customCardLinksClass?:string; 
    customSubTitleClass?:string;
}

const Tile = ({blockHeaderTitle,
    blockHeaderDescription,
    tiles,
    customImageClass,
    customCardClass,
    custombodyClass,
    customTitleClass,
    customFooterClass,
    isBodyLinkTrue,
    isFooterLinkTrue,
    blockFooterButtons,
    blockFooterText,
    baseURL,
    custombodyHeight,
    customCardLinksClass,
    customSubTitleClass
    
}:TileProps) => {

    return(
        <>
        <div className="container liquid-container">
            <div className="spacer30"></div>
            {blockHeaderTitle &&(
                <div>

                <h2 className="bellSlimBold txtCenter txtBlack txtBold txtSize32 letterSpacing
					txtSize26-xs margin-15-top">{blockHeaderTitle}</h2>

                   <div className="spacer30"></div>
                </div>
            )}
            {blockHeaderDescription &&(
                <p className="txtCenter middle pTxtWidth">{blockHeaderDescription}</p>
            )}
            <div className="container padLR-15-xs padLR-0-sm liquid-container bgWhite">
                <div className="container-flex-box-wrap tile-card">
                    {tiles?.map((tile:any,index:number) => {
                        return(
                            <Card
                                title={tile.title}
                                subTitle={tile.subTitle}
                                description={tile.description}
                                body=""
                                keyNumber={index}
                                logoImageUrl={tile.logoImageUrl}
                                stackedImageUrl={tile.stackedImageUrl}
                                showBorder={false}
                                customCardClass={customCardClass}
                                customTitleClass={customTitleClass}
                                custombodyClass={custombodyClass}
                                customFooterClass=""
                                customImageClass=""
                                custombodyHeight={custombodyHeight}
                                customCardWdithClass=""
                                stackedImageFullWidth={tile.stackedImageFullWidth} 
                                url ={tile.url}
                                customCardLinks={tile.customCardLinks}
                                customCardLinksClass={customCardLinksClass}
                                customSubTitleClass={customSubTitleClass}
                                />
                        );                        
                    })}
                </div>
            </div>
        </div>
        </>
    )
};

export default Tile;