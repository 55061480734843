const PageAtBottom = () =>{
    return(
        <div className="bgGray19 back-to-top">
            <div className="container txtCenter">
                <div className="spacer30 clear"></div>
                <a className="scrollToTop btn btn-primary" href="#">
                    <i className="icon icon icon-back-to-top"
                    aria-hidden="true"></i>&nbsp;&nbsp;Back to top
                </a>

                <div className="spacer30 clear"></div>
            </div>
        </div>
    )
}

export default PageAtBottom;