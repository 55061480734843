import Banner from "../../components/Banner/Banner";
import LeaderListData from "../../json/leaderlistpage.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import SubFooter from "../../components/SubFooter/SubFooter";

const LeaderListBanner = LeaderListData.bannerSection;
const LeaderListPage = LeaderListData.LeaderList;

const handleBannerSearchText = (searchValue: any) => {
    console.log("searchValue", searchValue);
  };
const bannerText={
    "title": LeaderListBanner[0]?.bannertext,
    "description":LeaderListBanner[0]?.bannerdescription
}

const LeaderList = () => {
    return (
        <>
          <MetaTag title={ LeaderListData.title } description={ LeaderListData.description } />
          
          {/* <Banner 
            isCarousle={false}
            bannerCardPosition={"center"}
            bannerCardColor={""}
            bannerImages={LeaderListBanner}
            stretchFullWidth={true}
            bannerClass={""}
            bannerJobContentFlag={false}
            bannerTextFlag={true}
            bannerText={bannerText}
            handleBannerSearchText={handleBannerSearchText}/> */}

            <div className="container-flex-box-wrap">
                <div className="container liquid-container">
                    <div className="spacer30 "></div>
                        <h2 className="bellSlimBold txtBlack txtCenter txtBold txtSize24 letterSpacing
                                                    txtSize26-xs margin-15-top">
                        {LeaderListData.title}
                        <div className="spacer20"></div>
                        </h2>
                        <div className="row row-flex">
                            {LeaderListPage.map(item => (
                                    <div className="col-md-4 col-sm-4 pad-30-bottom txtCenter middle">
                                        <div className="contentBox-aboutus display-flex">
                                            <a href={`/leader/${item.root}`}>
                                                <div className="contentBox-img1">
                                                    <img src={item.leaderImage} className="aboutuslogowid" alt={item.leaderName} />
                                                </div>
                                                <div className="spacer10"></div>

                                                <h3 className="bellSlimBold txtBlack titleTxt leadership-pad">
                                                   {item.leaderName}
                                                    &nbsp;
                                                    <img src="./content/img/arrow-icon.png" />
                                                </h3>

                                                <p className="txtSize16 txtBlack titleTxt txtCenter leadership-pad">
                                                    {item.leaderPosition}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
            </div>
    
            <SubFooter
                title="Need more information?"
                buttonText="Contact us"
                buttonUrl="/contactus"
                isCustomInfo={true}
                moreInformationText="Connect with us today to learn more about our services and solutions"
                moreInformationMailToId="Email: info@bceglobaltech.com"
            />
        </>
    ) 
}
export default LeaderList;