import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const Popup = ({title,bodyContent,handleClose, closeButtonClassName,closeButtonText}:any) => {

    const [showPop, setShowPop] = useState(true);   
    return(
        <div>
      
        <Modal show={showPop} onHide={handleClose} animation={false}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
               {bodyContent}
                </div>
            </Modal.Body>
            <Modal.Footer>
            <button
                   
                   onClick={() => (handleClose ? handleClose(false) : {})}
                   className={closeButtonClassName}
                   type="button"
                 >
                  {closeButtonText}
                 </button>                
            </Modal.Footer>
        </Modal>
    </div>       
    )
}

export default Popup;