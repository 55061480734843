import React, { useRef, useEffect, useState  } from 'react'
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha-enterprise";

interface FormProps {
    onSubmit: (data: FormDataDetails) => void;
}
  
  export interface FormDataDetails {
    firstName: string;
    lastName: string;
    email: string;
    comments: string;
    isButtonDisabled:boolean;
  }


const Form = ({ onSubmit }: FormProps) =>{
    const navigation = useNavigate();

    const [formData, setFormData] = useState<FormDataDetails>({ firstName: '', lastName: '',email: '',comments: '', isButtonDisabled:true });
    const [isFormValid, setisFormValid] = useState(true);
    const [isFirstNameValid,setIsFirstNameValid] = useState(true);
    const [isLastNameValid,setIsLastNameValid] = useState(true);
    const [isEmailValid,setIsEmailValid] = useState(true);
    const [emailErrorMessage,setEmailErrorMessage] = useState('Please enter email address');
    const [isCommentsValid,setIsCommentsValid] = useState(true);
    const [token,setToken] = useState('');
    const [isRecaptchaError,setIsRecaptchaError] = useState(false);
    
    const recaptchaRef = React.createRef<ReCAPTCHA>();
    
    let errors = {firstName:'',lastName:'',email:'',comments:'', isCommentsValid:true};



    function validateForm() 
    {
      let isFormValid = true;

      if (!formData.firstName) {
          setIsFirstNameValid(false);
          isFormValid = false;
      }
      else{
          setIsFirstNameValid(true);
      }

      if (!formData.lastName) {
          setIsLastNameValid(false);
          isFormValid = false;
      }
      else{
          setIsLastNameValid(true);
      }

      if (!formData.email) {
          setIsEmailValid(false);
          setEmailErrorMessage('Please enter email address');
          isFormValid = false;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          setEmailErrorMessage('Please enter a valid email address');
          setIsEmailValid(false);
          isFormValid = false;
      }
      else{
          setIsEmailValid(true);
      }

      if (!formData.comments) {
          setIsCommentsValid(false);
          isFormValid = false;
      }
      else{
          setIsCommentsValid(true);
      }            
      return isFormValid;
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  }
  
  function handleTextAreaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  }

  function handleCheckBoxChange(event: React.ChangeEvent<HTMLInputElement>) { 
      setFormData({ ...formData, ["isButtonDisabled"]: !event.target.checked });
  }

  
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let isFormValid = validateForm();

      if (isFormValid) {
        try{
            const tok = await recaptchaRef.current?.executeAsync();

            var eventData = {
                "event": {
                    "token": tok,
                    "siteKey": process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY,
                    "userAgent": navigator.userAgent,
                    "ja3": process.env.REACT_APP_RECAPTCHA_ENTERPRISE_JA3,
                    "expectedAction": process.env.REACT_APP_RECAPTCHA_ENTERPRISE_ACTION
                }
            };
        
            const assess_url = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_ASSESSMENTS_URL
            const res = await fetch((assess_url !== undefined) ? assess_url : '', {
                method: "POST",
                body: JSON.stringify(eventData),
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json"
                },
              });
        
            const data = await res.json();
            setIsRecaptchaError(true);
                if (data !== null && data !== undefined) {
                            if (data.tokenProperties !== null && data.tokenProperties !== undefined && data.tokenProperties.valid) {
                                //if (data.tokenProperties.action ==="submit") {
                                    console.log('user score ' + data.riskAnalysis.score);
                                    if (data.riskAnalysis.score >= 0.5) {
                                        setIsRecaptchaError(false);
                                        onSubmit(formData);                                     
                                    }
                                //}        
                            }  
                }
        
        }
        catch(e)
        {
            setIsRecaptchaError(true);
            console.log('error while getting google recaptcha score!')
        }

      } else {
          console.log('Form has errors. Please correct them.');
      }              
   
  }

  function redirectToHome() {
    navigation('/');
   }


    return(
        <div className="liquid-container container pad-15-xs">
                <div className="panel-wrapper">
                    {/* <div className="spacer15 d-none d-md-block clear" aria-hidden="true"></div>
                    <div className="spacer15 d-sm-block d-md-none d-xs-none clear" aria-hidden="true"></div> */}
                    <div className="spacer20 d-xs-none d-sm-none d-md-none clear" aria-hidden="true"></div>
                    <form id="formContactus"  onSubmit={handleSubmit}  >

        <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" value="{token}" />           
        <div className="spacer15" aria-hidden="true"></div>
        <div className="spacer15 d-xs-none d-sm-block d-md-block" aria-hidden="true"></div>

     {!isRecaptchaError &&   <div id="divContactUs" className="borderRadiusAll10 contactUsMargin no-border-xs bgWhite borderGrayLight6  pad-30-sm pad-30-md pad-30-top-xs  pad-h-15-sm pad-h-0-xs">


            <div className="col-md-8 col-sm-11 txtLine">
                <h4 className="txtBlack2 bellSlim txtBold txtSize20 no-margin line-height-22">How can we help?</h4>
            </div>
            <div className="line-height-18">
                <div className="spacer15 d-none d-sm-block clear" aria-hidden="true"></div>
            </div>
            <div className="spacer40 d-block d-sm-none clear" aria-hidden="true"></div>
            <div className="spacer1 bgGrayLight6 negative-15margin-xs" aria-hidden="true"></div>
            <div className="spacer15 d-none d-sm-block clear" aria-hidden="true"></div>
            <div className="spacer40 d-block d-sm-none clear" aria-hidden="true"></div>
            <fieldset>
                <div className="spacer30 d-none d-sm-block clear" aria-hidden="true"></div>
                <div className="spacer30 d-block d-sm-none clear" aria-hidden="true"></div>              
                <div className="container-flex-box-wrap line-height-18 block-xs form-group no-margin-bottom">
                    <div className="col-sm-3 col-md-2 top-align-self margin-30-right no-margin-xs w-100-xs">
                        <div className="txtRight txtLeft-xs form-label-wrap">
                            <div className="spacer10 d-none d-sm-block clear" aria-hidden="true"></div>
                            <label id="lblFirstNameStar" className="no-margin-bottom" >* <span id="lblFirstName" className="txtBold txtBlack2 line-height-18">First name</span></label>
                        </div>
                        <div className="spacer5 clear" aria-hidden="true"></div>
                    </div>
                    <div className="col-sm-8 col-md-9 top-align-self container-flex-box-wrap">
                        <div className="width-100-percent-xs width-280-md width-280-sm container-flex-box-no-wrap d-block">
                            <div className="fui-form-body">
                                <input maxLength={40} className="width-250 form-control new-form-control  txtSize16" 
                                       id="txtFirstName" name="firstName" type="text" value={formData.firstName} onChange={handleInputChange}  />
                            </div>
                            { !(isFirstNameValid) &&   <div id="divFirstName" >
                                <span id="FirstNameValidation" className="validation-message"><i id="firstNameValidationIcon" className="icon icon-exclamation-circled txtSize18 txtRed icon-position" aria-hidden="true" ></i>
                                Please enter first name</span>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="spacer20 d-none d-sm-block clear" aria-hidden="true"></div>
                <div className="spacer30 d-block d-sm-none clear" aria-hidden="true"></div>
                <div className="container-flex-box-wrap line-height-18 block-xs form-group no-margin-bottom">
                    <div className="col-sm-3 col-md-2 top-align-self margin-30-right no-margin-xs w-100-xs">
                        <div className="txtRight txtLeft-xs form-label-wrap">
                            <div className="spacer10 d-none d-sm-block clear" aria-hidden="true"></div>
                            <label id="lblLastNameStar" className="no-margin-bottom" >* <span id="lblLastName" className="txtBold txtBlack2 line-height-18">Last name</span></label>
                        </div>
                        <div className="spacer5 clear" aria-hidden="true"></div>
                    </div>
                    <div className="col-sm-8 col-md-9 top-align-self container-flex-box-wrap">
                        <div className="width-100-percent-xs width-280-md width-280-sm container-flex-box-no-wrap d-block">
                            <div className="fui-form-body">
                                <input maxLength={40} className="width-250 form-control new-form-control  txtSize16" 
                                      id="txtLastName" name="lastName" type="text"  value={formData.lastName} onChange={handleInputChange}   />
                            </div>
                            { !(isLastNameValid) &&       <div id="divLastName" >
                                <span id="LastNameValidation" className="validation-message"><i id="lastNameValidationIcon" className="icon icon-exclamation-circled txtSize18 txtRed icon-position" aria-hidden="true" ></i>
                                Please enter last name</span>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="spacer20 d-none d-sm-block clear" aria-hidden="true"></div>
                <div className="spacer30 d-block d-sm-none clear" aria-hidden="true"></div>

                <div className="container-flex-box-wrap line-height-18 block-xs form-group no-margin-bottom">
                    <div className="col-sm-3 col-md-2 top-align-self margin-30-right no-margin-xs w-100-xs">
                        <div className="txtRight txtLeft-xs form-label-wrap">
                            <div className="spacer10 d-none d-sm-block clear" aria-hidden="true"></div>
                            <label id="lblEmailStar" className="no-margin-bottom" >* <span id="lblEmail" className="txtBold txtBlack2 line-height-18">Email</span></label>
                        </div>
                        <div className="spacer5 clear" aria-hidden="true"></div>
                    </div>
                    <div className="col-sm-8 col-md-9 top-align-self container-flex-box-wrap">
                        <div className="width-250 width-100-percent-xs width-280-md width-280-sm container-flex-box-no-wrap d-block">
                            <div className="fui-form-body">
                                <input maxLength={100} id="txtEmailAddress" name="email" className="form-control new-form-control  txtSize16"
                                       type="text" value={formData.email} onChange={handleInputChange}   />
                            </div>
                            { !(isEmailValid) && <div id="divEmailValidationIcon"  >
                                <span id="spnEmailValidation" className="validation-message"><i id="emailValidationIcon" className="icon icon-exclamation-circled txtSize18 txtRed icon-position" aria-hidden="true" ></i>
                                {emailErrorMessage}</span>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="spacer20 d-none d-sm-block clear" aria-hidden="true"></div>
                <div className="spacer30 d-block d-sm-none clear" aria-hidden="true"></div>

               
                <div className="container-flex-box-wrap line-height-18 block-xs form-group no-margin-bottom">
                    <div className="col-sm-3 col-md-2 top-align-self margin-30-right no-margin-xs w-100-xs">
                        <div className="txtRight txtLeft-xs form-label-wrap">
                            <label className="no-margin-bottom" >*<span className="txtBold txtBlack2 line-height-18">Comments</span></label>
                        </div>
                        <div className="spacer5 clear" aria-hidden="true"></div>
                    </div>
                    <div className="col-sm-8 col-md-9 top-align-self container-flex-box-wrap">
                        <div className="container-flex-box-wrap width-100-percent-xs">
                            <div className="width-100-percent-xs width-434-md width-434-sm container-flex-box-no-wrap hidetextarea">
                            <div className="fui-form-body">
                                <textarea maxLength={1000} className="form-control new-form-control textarea-size txtSize14 textarea-resize-none height-120 height-134-xs" data-type="text"
                                          id="txtComments" name="comments" rows={5}    value={formData.comments} onChange={handleTextAreaChange}  ></textarea>
                            </div>      
                            { !(isCommentsValid) &&   <div id="divComments"  >
                                <span id="spnCommentsValidation" className="validation-message"> <i id="commentsIcon" className="icon icon-exclamation-circled txtSize18 txtRed icon-position" aria-hidden="true" ></i>
                                Please enter comments</span>
                            </div> }                    
                        </div>
                       
                        </div>
                    </div>

                  
                    <div className="spacer15 clear" aria-hidden="true"></div>
                    <div className="col-md-offset-2 col-sm-offset-3 col-xs-12">

                        <div className="pad-30-left no-pad-left-xs">
                        


                            <div className="clear spacer30"></div>

                            <div className="txtSize12 line-height-18">

                                <div className="d-flex ">
                                    <div className="no-margin max-width-500-sm form-terms">
                                        <input className="form-terms-chk-box" type="checkbox" role="checkbox" aria-label="Privacy Text" id="chkPrivacyText" onChange={handleCheckBoxChange}  name="isChecked" />
                                        <p className="form-terms-conditions">I agree to the use or processing of my personal information by BCE Global Tech to fulfil this request.
                                        <br/>By clicking the Submit button below, I agree to receive correspondence from BCE Global Tech.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clear spacer30"></div>    
                            <ReCAPTCHA sitekey="6LeX7wEqAAAAAK4YbGNF1opwvgXgkb2yPbQNrrpi" size="invisible" ref={recaptchaRef} />         
                            <div className="btn-block-xs">               
                            <button id="BtnSubmit" name="isButtonDisabled" className="g-recaptcha btn btn-default bottom-align-self  btn-block-sm"
                                    type="submit" disabled={formData.isButtonDisabled} >
                                Submit
                            </button>     
                            </div>                     
                            <div className="clear spacer10"></div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>}

        {isRecaptchaError && <div id="divServerError" className="borderRadiusAll10 contactUsMargin no-border-xs bgWhite borderGrayLight6  pad-30-sm pad-30-md pad-30-top-xs  pad-h-15-sm pad-h-0-xs">
                            <div className="d-flex flex-row justify-content-start mt-3 ms-5 contact-form-container success_form">
                                <div className="d-flex flex-column w-60 p-5">
                                    <div className="d-flex flex-row justify-content-start">
                                        <img alt="check" loading="lazy" width="60" height="60" decoding="async" data-nimg="1" className="faq_icon__7cn_T pb-3 align-top pe-3 image-transparent" src="content/img/BIG_CHECK_CONFIRM_290b868c67.svg" />
                                        <div className="success_info padLR-30">
                                            <p><span className="txtSize22" ><strong>Uh oh! Sorry, we encountered an error. Please try again later.</strong></span></p>
                                            <div className="clear spacer20"></div>
                                            <p><button onClick={redirectToHome} className="btn btn-default button-height-blue bottom-align-self btn-block-xs btn-block-sm">Back to homepage</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}       
    </form>
                </div>
            </div>        

    );
};

export default Form;