import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage/homePage';
import CareerPage from './pages/careerPage/careerPage';
import CareerSearch from './pages/careerPage/careerSearchPage';
import CareerDetail from './pages/careerPage/careerDetailPage';
import Contactus from './pages/contactusPage/contactusPage';
import Resources from './pages/resourcesPage/resourcesPage';
import ResourcesDetail from './pages/resourcesPage/resourceDetailPage';
import NotFoundPage from './pages/notFoundPage/notFoundPage';
import LeaderDetail from './pages/aboutUsPage/leaderDetailPage';
import LeaderList from './pages/aboutUsPage/leaderListpage';

const Router = () =>{
    return (
        <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/career' element={<CareerPage/>}/>
            <Route path='/resources' element={<Resources/>}/>
            <Route path='/resources/:resource_name' element={<ResourcesDetail/>}/>
            <Route path='/careers/:jobCategory' element={<CareerSearch/>}/>
            <Route path='/career-details/:jobId' element={<CareerDetail/>} />
            <Route path='/contactus' element={<Contactus/>}/>
            <Route path='/leadership' element={<LeaderList/>}/>
            <Route path='/leader/:leadername' element={<LeaderDetail/>}/>
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
}

export default Router;