import React, { Component, ReactNode, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import Tile from "../../components/Tile/Tile";
import Collaborations from "../../components/Collaborations/Collaborations";
import SubFooter from "../../components/SubFooter/SubFooter";
import ResourcePageData from "../../json/minify/resourcePage.json";
import MetaTag from "../../components/MetaTag/MetaTag";

const resourceBannerList = ResourcePageData.bannerSection;
const resourceTileList = ResourcePageData.tileSection;
const resourceCollaborationsList = ResourcePageData.collaborationSection;

const Resources = () => {
  const handleBannerSearchText = (searchValue: any) => {
    console.log("searchValue", searchValue);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, [])

  return (<>
    <MetaTag title={ ResourcePageData.title } description={ ResourcePageData.description }/>
    <Banner
      isCarousle={false}
      bannerCardPosition={"center"}
      bannerCardColor={""}
      bannerImages={resourceBannerList}
      stretchFullWidth={true}
      bannerClass={""}
      bannerJobContentFlag={false}
      handleBannerSearchText={handleBannerSearchText}
    />
    <div className="bgWhite pad-15-xs">
      <div className="container liquid-container">

        <div className="spacer30 hidden-xs"></div>
        <h1 className="bellSlimBold right-blck txtBlack txtCenter txtBold txtSize32 letterSpacing
					          txtSize26-xs margin-15-top">Resources</h1>
        <div className="spacer30"></div>
        <div className="inline-blck w-100 right-blck">

          Take a deeper dive into innovation. Learn the ins and outs of 5G, MEC (multi-access edge computing), IoT and how to set up your projects.

        </div>

        <div className="spacer30"></div>
        <div className="spacer30"></div>
        <div className="spacer30 hidden-md hidden-lg "></div>
        <hr className="footer-line" />

      </div>

      <Tile
        blockHeaderTitle=""
        blockHeaderDescription=""
        tiles={resourceTileList}
        customImageClass=""
        customCardClass={""}
        custombodyClass={"resource-tile-card text-left"}
        customTitleClass={"filter-val active-fil bor-box width-30"}
        customFooterClass=""
        isBodyLinkTrue={false}
        isFooterLinkTrue={false}
        blockFooterButtons=""
        blockFooterText=""
        baseURL=""
        custombodyHeight={""}
        customCardLinksClass={"icon more-link txtBold line-height-07"}
        customSubTitleClass={"resource-sub-title txtBlack pad-bottom-70"}
      />
      <Collaborations
       mainTitle="Collaborations"
       customMainTitleClass="bellSlimBold txtBlack txtCenter txtSize32 letterSpacing
					txtSize26-xs margin-15-top"
       customBodyClass="pad-20-top pad-40-bottom pad-20-top-xs pad-20-bottom-xs txtSize16"
       customTitleClass="bellSlimBold collaborations_name pb-3 txtBlack"
       customImageClass="txtCenter-xs middle-xs w-70"
       collaborations={resourceCollaborationsList}
      />

      
    </div>




  </>);
};

export default Resources;