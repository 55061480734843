import { useParams } from "react-router-dom";
import LeaderListData from "../../json/leaderlistpage.json";
import MetaTag from "../../components/MetaTag/MetaTag";

const LeaderDetailList = LeaderListData.LeaderList;

const LeaderDetail = () => {

    const { leadername } = useParams();
    const leader = LeaderDetailList.find(item => item.root === leadername);

    return(
    <>
    <MetaTag title={ "BCE Global Tech" } description={ "" }/>
        <div className="container liquid-container">
            <div className="clear spacer30"></div>
            <div className="backButton-pad-left">
                <a href="/leadership">
                    <i className="icon icon-chevron txtBlue flip" aria-hidden="true"></i>
                    <p className="txtSize14 txtBlue-imp txtBold inlineBlock pad-5-left">Back</p>
                </a>
            </div>
            <div className="clear spacer20"></div>
            
                <div className="container liquid-container pad-15-xs">
                    <div className="pad-30 borderradius w-100-xs contentBox-description-md contentBox-description-sm">
                        <div className="middle txtCenter">
                            <div className="contBox-detailimage">
                                <img src={leader?.leaderImage} className="aboutusdetaillogowid" alt={leader?.leaderName} />
                            </div>
                        </div>
                    </div>
                    
                        <div className="container_txtcenter w-sm-60 pad-left-20-md pad-left-20-sm w-100-xs pad-top-20-xs">

                            <h1 className="txtSize36 txtCenter bellSlimBold txtBlack titleTxt">{leader?.leaderName}</h1>
                            <p className="txtCenter txtSize18 titleTxt ">{leader?.leaderPosition}</p>
                            <p></p>
                            <hr className="w-sm-100 footer-line"></hr>
                            <p className="clear spacer10"></p>
                            <p className="txtSize16 text-justify" dangerouslySetInnerHTML={{ __html: leader?.leaderDescription || ""}}></p>
                            <br />
                            
                        </div>
                </div>
            
            <div className="spacer30 clear"></div>    
        </div>
    </>);
};

export default LeaderDetail;