import React, { Component,ReactNode } from "react";
import Banner from "../../components/Banner/Banner";
import Tile from "../../components/Tile/Tile";
import SubFooter from "../../components/SubFooter/SubFooter";
import HomePageData from "../../json/minify/homepage.json";
import MetaTag from "../../components/MetaTag/MetaTag";

const homeBannerList = HomePageData.bannerSection;
const homeTileList = HomePageData.tileSection;
const pageTitle = HomePageData.title;


const Home = () => {
  const handleBannerSearchText = (searchValue:any) =>{
    console.log("searchValue",searchValue);
  };
    return(
    <>
        <MetaTag title={ pageTitle } description={ HomePageData.description }/>
        <Banner 
          isCarousle={true}
          bannerCardPosition={"center"}
          bannerCardColor={""}
          bannerImages={homeBannerList}
          stretchFullWidth={true}
          bannerClass={""}
          bannerJobContentFlag={false}
          handleBannerSearchText={handleBannerSearchText}
        />
        <div className="bgWhite pad-15-xs">
          <div className="container liquid-container">
              <div className="spacer30 hidden-xs"></div>
              <h1 className="bellSlimBold txtBlack txtCenter txtBold txtSize32 letterSpacing
					          txtSize26-xs margin-15-top">Get to know us. We'd like to know you.</h1>
              <div className="spacer30"></div>      
              <div className="inline-blck w-100">
                  <div className="bgGray19 left-blck w-30 w-sm-40 pad-30 borderradius w-100-xs contentBox-description-md contentBox-description-sm">
                      <p className="txtBlack txtBold txtSize18">At BCE Global Tech we are on a mission to modernize global connectivity, one connection at a time.</p>
                      <p className="txtSize16">We aim to build the highway to the future of communications, media and entertainment, determined to emerge as a powerhouse within the technology landscape in India.</p>
                  </div>  
                  <div className="right-blck w-70 w-sm-60 pad-left-20-md pad-left-20-sm w-100-xs pad-top-20-xs">
                    <p className="txtSize16">At BCE Global Tech we are on a mission to modernize global connectivity, one connection at a time. We aim to build the highway to the future of communications, media and entertainment, determined to emerge as a powerhouse within the technology landscape in India.</p>
                    <p className="txtSize16">We bring ambitions to life through design thinking that bridges the gaps between people, devices and beyond, fostering unprecedented customer satisfaction through technology.</p>
                    <p className="txtSize16">Our core values support a customer-centric approach and the harnessing of cutting-edge technology to provide business outcomes with positive societal impact. Guided by innovation and a commitment to progress, we’re shaping a brighter future for the generations of today and tomorrow.</p>
                    <p className="txtSize16">If you would like to be a part of a team of thought-leaders pioneering advancements in 5G, MEC, IoT and cloud-native architecture, we’d love to hear from you.</p>
                  </div>
              </div>      
          </div>
              
          <Tile 
            blockHeaderTitle=""
            blockHeaderDescription=""
            tiles={homeTileList}
            customImageClass=""
            customCardClass={""}
            custombodyClass={"home-tile-card"}
            customTitleClass={"bellSlimBold txtSize24"}
            customFooterClass=""
            isBodyLinkTrue={false}
            isFooterLinkTrue={false}
            blockFooterButtons=""
            blockFooterText=""
            baseURL=""
            custombodyHeight={""}
            />            
        </div>  
        <SubFooter
          title=""
          buttonText=""
          buttonUrl=""
          isCustomInfo={false}
          moreInformationText=""
        />
    </>);
};

export default Home;