import React, { useState , useEffect} from 'react'
import CompanyAddress from "../../components/Layout/CompanyAddress/CompanyAddress";
import PageTitle from "../../components/Layout/PageTitle/PageTitle";
import Form, {FormDataDetails} from "../../components/Form/Form";
import { useNavigate } from "react-router-dom";
import MetaTag from '../../components/MetaTag/MetaTag';


export default function Contactus(){
    const [isEmailSent,setIsEmailSent] = useState(false);
    const [isEmailError,setIsEmailError] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(formData: FormDataDetails) {
        let eventData = {firstName:formData.firstName,lastName:formData.lastName,
            email:formData.email,comments:formData.comments};
       
        try {	
            const contact_us_url = process.env.REACT_APP_AWS_CONTACT_US_FORM_URL
            const res = await fetch((contact_us_url !== undefined) ? contact_us_url : '', {
                method: "POST",
                body: JSON.stringify(eventData),
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json"
                },
              });

			const data = await res.json();
			if (data !== null && data !== undefined) {
                if (data.statusCode !== null && data.statusCode !== undefined && data.statusCode === 200) {
                    setIsEmailSent(true);
                }
            }
		} catch (err) {
            setIsEmailSent(true);
            setIsEmailError(true);
			console.log(err);
		}
    }

    function redirectToHome() {
        navigate('/');
    }
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      },[])

    return(
        <main>
        <MetaTag title="Contact us at BCE Global Tech" description={ "" }/>
        <PageTitle title="Contact us" />
        <div className="container liquid-container bgWhite">            
            {!isEmailSent && <Form onSubmit={handleSubmit} />}
            {isEmailSent &&  !isEmailError &&  
             <div className="panel-wrapper">
             <div className="spacer15 d-none d-md-block clear" aria-hidden="true"></div>
             <div className="spacer15 d-sm-block d-md-none d-xs-none clear" aria-hidden="true"></div>
             <div className="spacer20 d-xs-none d-sm-none d-md-none clear" aria-hidden="true"></div>
             
            <div id="divSuccess" className="borderRadiusAll10 messageBoxMargin no-border-xs bgWhite borderGrayLight6  pad-30-sm pad-30-md pad-30-top-xs  pad-h-15-sm pad-h-0-xs">
        <div className="d-flex flex-row justify-content-start mt-3 ms-5 contact-form-container success_form">
            <div className="d-flex flex-column w-60 p-5">
                <div className="d-flex flex-row justify-content-start">
                    <img alt="check" loading="lazy" width="60" height="60" decoding="async" data-nimg="1" className="faq_icon__7cn_T pb-3 align-top pe-3 image-transparent" src="../content/img/BIG_CHECK_CONFIRM_290b868c67.svg" />
                    <div className="success_info padLR-30">
                        <p><span className="txtSize22" ><strong>Your response was submitted.</strong></span></p>
                        <p><span className="message-text-16" >Our team will respond to your inquiry within 72 business hours.</span></p>
                        <div className="clear spacer20"></div>
                        <p><button onClick={redirectToHome} className="btn btn-default button-height-blue bottom-align-self btn-block-message-xs btn-block-sm">Back to homepage</button></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>}

{isEmailSent && isEmailError &&   
             <div className="panel-wrapper">
             <div className="spacer15 d-none d-md-block clear" aria-hidden="true"></div>
             <div className="spacer15 d-sm-block d-md-none d-xs-none clear" aria-hidden="true"></div>
             <div className="spacer20 d-xs-none d-sm-none d-md-none clear" aria-hidden="true"></div>
             
    <div id="divServerError" className="borderRadiusAll10 messageBoxMargin no-border-xs bgWhite borderGrayLight6  pad-30-sm pad-30-md pad-30-top-xs  pad-h-15-sm pad-h-0-xs">
        <div className="d-flex flex-row justify-content-start mt-3 ms-5 contact-form-container success_form">
            <div className="d-flex flex-column w-60 p-5">
                <div className="d-flex flex-row justify-content-start">
                    <img alt="check" loading="lazy" width="60" height="60" decoding="async" data-nimg="1" className="faq_icon__7cn_T pb-3 align-top pe-3 image-transparent" src="../content/img/BIG_CHECK_CONFIRM_290b868c67.svg" />
                    <div className="success_info padLR-30">
                        <p><span className="txtSize22" ><strong>Uh oh! Sorry, we encountered an error. Please try again later.</strong></span></p>
                        <div className="clear spacer20"></div>
                        <p><button onClick={redirectToHome} className="btn btn-default button-height-blue bottom-align-self btn-block-message-xs btn-block-sm">Back to homepage</button></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>}
            <div className="clear spacer40"></div>
            <CompanyAddress />            
        </div>        
    </main>
    )
};