import React from "react";
import { Helmet }  from "react-helmet";
import MetaTagData from "../../json/metatags.json";

interface MetaTagProps{
    title:string;
    description:string;
    jobTitle?:string;
}
const MetaTag = ({title,description,jobTitle}:MetaTagProps) => {
    return(
        <Helmet>
            <title> {title} </title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
            <meta http-equiv="content-type" content="text/html; charset=UTF-8"></meta>        
            <meta charSet="utf-8"></meta>
            <meta name="description" content={description}/>
            <meta name="ahrefs-site-verification" content="4bee46beacfdb9dfcbc9a7e3b91afd7c552a90d4d7125efd96d4d89371e8aa18"/>
            <meta charSet="utf-8"/>
            <meta name="province" content="ON" />
            <meta name="language" content="en" />
            <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=1"/>
            <meta name="format-detection" content="telephone=no"/>                        
            {/* og for facebook & linkedin */}       
            <meta property="og:title" content={jobTitle} />
            <meta property="og:type" content="JobPosting"/>
            <meta property="og:image" content="/content/img/career-banner/Job-posting_Hero-banner_992px.jpg" />
            <meta property="og:description" content={description} />
            {/* og for twitter */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={jobTitle}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content=""/>
        </Helmet>
    );
};

export default MetaTag;