
interface PageTitleProps{
    title: string
}

const PageTitle = ({title}:PageTitleProps) =>{

    return(        
            <div className="bgGray19 back-to-top">
                <div className="container liquid-container">
                    <div className="spacer10 clear"></div>
                    <div>
                        <h1 className="component_title pt-3 txtBlack txtSize26-xs">{title}</h1>
                        </div>
                    <div className="spacer20 clear visible-xs"></div>
                    <div className="spacer30 clear"></div>
                </div>
            </div>
    );
};

export default PageTitle;