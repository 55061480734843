import React, { useEffect, useState, useRef  } from "react";
import Popup from "../Modals/Popup";
import { useNavigate } from "react-router-dom";



interface LinkItems {
    location: string;
}


interface asideList {
    jobId: string;
    jobTitle: string;
    location: string;
    jobCategory: string;
}

interface AsideBoxProps {
    title: string;
    description: string;
    subTitle: string;
    fullLine: boolean;
    asideBoxButtonClass: string;
    asideBoxButtonText: string;
    asideBoxButton: boolean;
    asideBoxInputClass: string;
    asideBoxInputPlaceHolder: string;
    asideBoxEmail: boolean;
    customBodyClass: string;
    asideList: boolean;
    asideListDetails: asideList[];
    asideSocialIcons: boolean;
    asideSocialTitle: string;
    asideBoxItemShowLength: number;
    asideBoxFolderIcon: boolean;
    asideBoxLocationIcon: boolean;


}

const AsideBox = ({

    title,
    description,
    subTitle,
    fullLine,
    asideBoxButtonClass,
    asideBoxButtonText,
    asideBoxButton,
    asideBoxInputClass,
    asideBoxInputPlaceHolder,
    asideBoxEmail,
    customBodyClass,
    asideList,
    asideListDetails,
    asideSocialIcons,
    asideSocialTitle,
    asideBoxItemShowLength,
    asideBoxFolderIcon,
    asideBoxLocationIcon

}: AsideBoxProps) => {

    
    const navigation = useNavigate();
    const socialFaceBook = process.env.REACT_APP_FACEBOOK_URL?.replace("#TITLE#",asideSocialTitle).replace("#URL#",window.location.href);
    const socialLinkedIn = process.env.REACT_APP_LINKEDIN_URL?.replace("#URL#",`${encodeURIComponent(window.location.href)}`).replace("#TITLE#",asideSocialTitle);
    const socialTwitter = process.env.REACT_APP_TWITTER_URL?.replace("#TITLE#",asideSocialTitle).replace("#URL#",window.location.href);

    const [selectedIndex, setSelectedIndex] = useState<number | 0>(0);
    const [selectedJob, setSelectedJob] = useState('');
    const [selectedJobId, setSelectedJobId] = useState('');
    const getMoreLocations = () => {

        return (
            <div>

                <div className="margin-b-20">

                    <ul>
                        {/* {asideListDetails[selectedIndex].asideBoxLinks.map(item => (
                            <li className="pad-10">
                                {item.location}
                            </li>
                        ))} */}
                    </ul>
                </div>

            </div>
        );
    };
    const containerRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);
    
    const toggleShowMore = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
          }
        setShowMore(!showMore);
    };
    const moreLocations = getMoreLocations();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        return () => setShowModal(false);
        
    }, []);
 
   
    const handleClose = () => setShowModal(false);
    const handleShow = (index: number) => {
        setShowModal(true);
        setSelectedIndex(index);
        //setSelectedJob(asideListDetails[selectedIndex].title);

    };

    const smilarJobRedirect = (jobId: string) => {
        setShowMore(false);
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
        navigation(`/career-details/${jobId}`);
        window.scrollTo(0, 0);
    };
    return (
        <div className={`${customBodyClass}`} >
            <p className={`bellSlimBold txtBlack txtSize20 mar-neg-top-10 ${asideList ? 'pad-30' : ''}`}>{title}</p>
            {fullLine == true && (
                <hr className="asidebox-fullline"></hr>
            )
            }

            <p className="txtSize16">{description}</p>
            <p className="txtBold txtSize16">{subTitle}</p>
            {asideBoxButton == true && (

                <div className={`${asideBoxButtonClass}`}>

                    <input type="text" className={`${asideBoxInputClass}`} placeholder={`${asideBoxInputPlaceHolder}`} />
                    <button className="btn btn-default">{asideBoxButtonText}</button>

                </div>

            )
            }

            {asideBoxEmail == true && (

                <div className={`${asideBoxButtonClass}`}>

                    <button className="btn">{asideBoxButtonText}</button>

                </div>

            )
            }

            {asideList == true && (
                <>
                    <div>
                        <div  ref={containerRef}  className={`asidebox-content pad-30 ${asideBoxItemShowLength > 0 ?  !showMore ? 'asidebox-content-fixed-height':'':''}`}>
                            {asideListDetails.map((list: any, index: number) => {
                                return(
                                    <div className="pad-40-bottom">
                                        <p onClick={() => smilarJobRedirect(list.jobId)} className="bellSlimBold txtBlack txtBold txtSize16 underline-on-hover cursor-pointer">{list.jobTitle}</p>
                                        <div className="col-md-4 col-sm-12">
                                            <div>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 512 512" fill="#A3A3A3" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#A3A3A3"><path fill="currentColor" d="M253.924 127.592a64 64 0 1 0 64 64a64.073 64.073 0 0 0-64-64Zm0 96a32 32 0 1 1 32-32a32.037 32.037 0 0 1-32 32Z" /><path fill="currentColor" d="M376.906 68.515A173.922 173.922 0 0 0 108.2 286.426l120.907 185.613a29.619 29.619 0 0 0 49.635 0l120.911-185.613a173.921 173.921 0 0 0-22.747-217.911Zm-4.065 200.444l-118.916 182.55l-118.917-182.55c-36.4-55.879-28.593-130.659 18.563-177.817a141.92 141.92 0 0 1 200.708 0c47.156 47.158 54.962 121.938 18.562 177.817Z" /></g></svg>
                                                    <span className="vertical-align-middle">&nbsp;&nbsp;{list.location}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-sm-12 pad-left-20-md">
                                            <p>
                                                {asideBoxFolderIcon == true && (
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" className="icon-Position" fill="#A3A3A3" x="246" y="246" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="#A3A3A3"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M22 19V9a2 2 0 0 0-2-2h-6.764a2 2 0 0 1-1.789-1.106l-.894-1.788A2 2 0 0 0 8.763 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z" /></g></svg>
                                                )}
                                                <span className="vertical-align-middle">&nbsp;&nbsp;{list.jobCategory}</span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <hr className="asidebox-fullline"></hr>
                        <div className="asidebox-footer pad-30">
                            {asideListDetails.length > asideBoxItemShowLength && (
                                <div className="asidebox-link">                                    
                                    <a onClick={toggleShowMore} className="toggle-button">
                                        {showMore ? 'Show Less' : 'Show More'}
                                    </a>
                                </div>)

                            }
                        </div>
                    </div>
                </>
            )}

            {asideSocialIcons == true && (

                <div className="footer-media-box">
                    <ul className="asidebox-media-items">
                        <li className="footer-media-item"><a href={socialFaceBook} target="_blank"><img src="/content/img/facebook.png" alt="facebook"/></a></li>
                        <li className="footer-media-item"><a href={socialLinkedIn} target="_blank"><img src="/content/img/linkedin-footer.png" alt="linkedin"/></a></li>
                        <li className="footer-media-item"><a href={socialTwitter} target="_blank"><img src="/content/img/twitt-footer1.png" alt="twitter"/></a></li>                       
                    </ul>
                </div>


            )}

            {showModal == true && (
                <div>
                    <Popup
                        title={selectedJob}
                        bodyContent={moreLocations}
                        handleClose={handleClose}
                        closeButtonClassName={"btn btn-default txtCenter"}
                        closeButtonText={"ok"}
                    />
                </div>
            )}
        </div>

    );

};
export default AsideBox;