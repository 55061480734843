import React from 'react';
import ReactPlayer from "react-player";

interface VideoPlayerProps{
   title?:string;
   description?:string;
   videoLink:string;
   controls:boolean;
}
const VideoPlayer = ({ title,
    description,
    videoLink,
    controls } : VideoPlayerProps) =>{

    return(
        <>                        
            <div className='player-wrapper'>
                <ReactPlayer className='react-player'                                
                    url={videoLink}
                    controls={controls}
                    width='100%'
                    height='100%'
                />
            </div>
        </>
    );    
};

export default VideoPlayer;