import React from "react";
import { Route, useParams ,useNavigate } from "react-router-dom";


interface SubFooterProps{
    title?:string;
    buttonText?:string;
    buttonUrl?:string;
    isCustomInfo:boolean;
    moreInformationText?:string;
    moreInformationLinkText?:string;
    moreInformationMailToId?:string;
}
const SubFooter = ({title,
    buttonText,
    buttonUrl,
    isCustomInfo,
    moreInformationText,
    moreInformationLinkText,
    moreInformationMailToId 
    

}:SubFooterProps) =>{
    const router = useParams();
    const navigation = useNavigate();
    return(
        
        <div className="container liquid-container">
            <div className="spacer30"></div>
            <div className="center bgBlue borderradius pad-left no-radius-xs">
                <div className="clear spacer30"></div>
                <div className="clear spacer15 hidden-xs"></div>
                {isCustomInfo ? (
                    <>
                     {title && (
                        <p className="bellSlim txtSize24 txtBold">{title}</p>  
                     )}
                     {moreInformationText && (
                        <p className="txtSize16 padLR-10">{moreInformationText}</p>
                        )} 
                        
                        <div className="clear spacer20"></div>
                        {buttonText && buttonUrl && (
                         <button className="btn btn-default button-white noBorder" onClick={(e) => {navigation(`${buttonUrl}`)}} >{buttonText}</button>
                    )} 

                    </>
                ) : (
                    <>
                        <p className="bellSlim txtSize24 txtBold">Ready to get started?</p>
                        <div className="clear spacer20"></div>
                        <button className="btn btn-default button-white noBorder" onClick={(e) => {navigation("/career")}} >Explore Careers</button>
                    </>
                )}
                <div className="clear spacer30"></div>
                <div className="clear spacer15 hidden-xs"></div>
            </div>
        </div>        
    );
};

export default SubFooter;